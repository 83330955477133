import React, { lazy, Suspense } from 'react';
import Loading from '../loading';

const MarkdownEdit = lazy(() => import('./edit/markdown_edit'));

class MarkdownBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  currentTheme() {
    return this.props.appAttributes.code_theme || 'idea';
  }

  // Render the editor.
  render() {
    if (this.props.edit) {
      return (
        <Suspense fallback={<Loading/>}>
          <MarkdownEdit {...this.props} theme={this.currentTheme()} />
        </Suspense>
      )
    } else {
      return null;
    }
  }
}

export default MarkdownBlock;
